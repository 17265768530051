<template>
  <div id="chatwoot-widget"></div>
</template>

<script>
import { mapState } from "vuex";

const { VUE_APP_CHATWOOT_TOKEN = false, VUE_APP_CHATWOOT_URL = false } =
  process.env;

export default {
  name: "ChatwootWidget",
  props: {
    type: {
      type: String,
      default: "expanded_bubble",
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      scriptEl: null,
    };
  },
  mounted() {
    if (!VUE_APP_CHATWOOT_TOKEN || !VUE_APP_CHATWOOT_URL) return;
    if (window.$chatwoot) {
      this.setUser();
      this.setAttributes();
      window.$chatwoot.toggleBubbleVisibility("show");
      return;
    }
    this.initChatwoot();
  },
  beforeDestroy() {
    if (this.scriptEl) document.body.removeChild(this.scriptEl);
    if (window.$chatwoot) {
      window.$chatwoot.toggle("close");
      window.$chatwoot.toggleBubbleVisibility("hide");
      // window.$chatwoot.reset();
    }
  },
  methods: {
    initChatwoot() {
      window.chatwootSettings = {
        position: "right",
        type: this.type,
        launcherTitle: "Precisa de ajuda?",
        darkMode: this.$vuetify.theme.dark ? "dark" : "light",
        customData: this.data,
        ...this.options,
      };

      const g = document.createElement("script");
      g.src = VUE_APP_CHATWOOT_URL + "/packs/js/sdk.js";
      g.defer = true;
      g.async = true;
      document.body.appendChild(g);

      g.onload = this.onLoad;
      this.scriptEl = g;
    },
    onLoad() {
      window.chatwootSDK.run({
        websiteToken: VUE_APP_CHATWOOT_TOKEN,
        baseUrl: VUE_APP_CHATWOOT_URL,
      });

      this.$nextTick(() => {
        this.setUser();
        this.setAttributes();
      });
    },
    setUser() {
      if (!this.user) return;

      window.$chatwoot.setUser(this.user.id, {
        name: this.user.name,
        email: this.user.email,
        avatar_url:
          this.user.photo ||
          `https://api.dicebear.com/7.x/adventurer-neutral/svg?seed=${this.user.id}.`,
        phone_number: `+${this.user.ddi}${this.user.phone}`.replace(
          /\D\+/g,
          ""
        ),
      });
    },
    setAttributes() {
      window.$chatwoot.setConversationCustomAttributes({
        ...this.data,
      });
    },
  },
  watch: {
    "$vuetify.theme.dark"(newVal) {
      window.$chatwoot.setColorScheme(newVal ? "dark" : "light");
    },
    type(newVal) {
      window.$chatwoot.setType(newVal);
    },
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
};
</script>

<style>
.woot-widget-bubble.woot-elements--right {
  z-index: 200 !important;
  @media (max-width: 960px) {
    bottom: 140px !important;
  }
}
.woot-widget-holder {
  @media (max-width: 960px) {
    bottom: calc(140px + 80px) !important;
  }
}
</style>
