<template>
  <v-dialog v-model="dialog" max-width="800" eager content-class="rounded-xl">
    <v-card class="rounded-xl">
      <div ref="map" style="height: 80vh; max-height: 500px; width: 100%"></div>
      <!-- <v-row class="mx-0">
        <v-col cols="12" md="7" lg="8">
        </v-col>
        <v-col>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6"
                  >Vendedores</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="seller in sellers" :key="seller.id">
              <v-list-item-content>
                <v-list-item-title>{{ seller.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  seller.Address.street
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row> -->
      <v-btn text @click="dialog = false" block class="mt-1">Fechar</v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
const { VUE_APP_MAPS_KEY } = process.env;
import { Loader } from "@googlemaps/js-api-loader";

const mapOptions = {
  zoom: 10,
  styles: [
    {
      featureType: "all",
      elementType: "labels",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "poi.business",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "poi.medical",
      elementType: "all",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "poi.school",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ visibility: "simplified" }],
    },
  ],
  disableDefaultUI: false,
  streetViewControl: false,
  mapTypeControl: false,
};
export default {
  props: {
    sellers: {
      type: Array,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    dialog: false,
    loader: null,
    map: null,
  }),
  methods: {
    open() {
      this.dialog = true;
      this.initMap();
    },
    async initMap() {
      const { lat, lng } = this.sellers[0].Address;

      // remove locations name layer
      const map = new google.maps.Map(this.$refs.map, {
        center: { lat, lng },
        ...mapOptions,
      });
      this.sellers.forEach((seller) => {
        const { lat, lng } = seller.Address;
        const marker = new google.maps.Marker({
          position: { lat, lng },
          map,
          title: seller.name,
        });

        marker.addListener("click", () => {
          this.$emit("posRouter", seller);
        });

        const tooltipWindow = new google.maps.InfoWindow({
          content: `<div class="mt-2">
            <span class="text-overline lh-1">Ver rota até</span>
            <h6 class="mb-0">${seller.name}</h6>
          </div>`,
        });
        google.maps.event.addListener(tooltipWindow, "domready", () => {
          const closeButton = document.querySelectorAll(
            ".gm-style-iw .gm-ui-hover-effect, .gm-style-iw-ch"
          );
          closeButton.forEach((button) => {
            button.style.display = "none";
          });
        });
        marker.addListener("mouseover", () => {
          tooltipWindow.open(map, marker);
        });
        marker.addListener("mouseout", () => {
          tooltipWindow.close();
        });
      });
      this.map = map;
    },
  },
  mounted() {
    this.$parent.$on("pos-map", this.open);
    this.loader = new Loader({
      apiKey: VUE_APP_MAPS_KEY,
      version: "weekly",
    });

    this.loader.load().then(() => {
      if (this.dialog) this.initMap();
    });
  },
};
</script>

<style></style>
