<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="900"
      contentClass="rounded-xl elevation-0"
      :overlay-opacity="0.9"
      persistent
      scrollable
    >
      <!-- Confirm ticket group and ticket block to finish sale -->
      <div
        class="d-flex align-center justify-center gap-8 v-dialog--scrollable"
      >
        <v-card
          class="flex-grow-1 flex-shirk-0 overflow-hidden rounded-xl"
          style="max-width: 450px"
        >
          <v-img
            :src="party.cover"
            :aspect-ratio="$vuetify.breakpoint.smAndDown ? 22 / 9 : 16 / 9"
          />
          <v-card-text class="pt-1">
            <span class="text-overline lh-1">
              {{ party.date | date("DD [de] MMMM - HH:mm") }}
            </span>
            <h5>
              {{ party.name }}
            </h5>
            <div class="d-block d-md-none pb-5">
              <BuyKitCart
                :items="formattedItemList"
                :totalValue="totalValue"
                :kit="kit"
              />
            </div>

            <v-alert type="primary" text dense>
              Ao clicar em "<i>Comprar</i>", o pacote será reservado para você.
              Finalize seu pagamento dentro de 30 minutos para garantir sua
              reserva.
            </v-alert>
            <v-scroll-y-transition hide-on-leave>
              <v-alert
                v-if="error"
                type="error"
                text
                border="left"
                class="mb-0 mt-2"
              >
                {{ error }}
              </v-alert>
            </v-scroll-y-transition>
          </v-card-text>

          <!-- Actions -->
          <v-card-actions class="align-end justify-space-between">
            <v-btn text @click="close" :disabled="loading"> Cancelar </v-btn>
            <v-btn
              color="primary"
              @click="runRecaptcha"
              large
              :loading="loading"
            >
              Comprar •
              {{ totalValue | currency(true) }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="d-none d-md-block rounded" style="max-width: 375px">
          <BuyKitCart
            scrollable
            class="pa-4"
            style="max-height: calc(100vh - 96px)"
            :items="formattedItemList"
            :totalValue="totalValue"
            :kit="kit"
          />
        </v-card>
      </div>
    </v-dialog>
    <re-captcha
      v-if="dialog"
      ref="recaptcha"
      @success="buyKit"
      @error="recaptchaError"
      @close="recaptchaError"
    />
  </div>
</template>

<script>
import SHOP from "@/services/shop";
import ReCaptcha from "@/components/global/ReCaptcha.vue";
import BuyKitCart from "./BuyKitCart.vue";
import { process } from "@/utils/shop/cart";

export default {
  components: { ReCaptcha, BuyKitCart },
  data: () => ({
    loading: false,
    error: false,
    dialog: false,
    kit: false,
  }),
  methods: {
    runRecaptcha() {
      this.loading = true;
      this.error = null;
      this.$refs.recaptcha.execute();
    },
    recaptchaError() {
      this.loading = false;
      this.error = "Erro ao validar o captcha";
    },
    async buyKit(recaptcha) {
      try {
        const response = await SHOP.buyKit(
          this.$route.params.orgSlug,
          this.$route.params.partyId,
          { recaptcha, amount: this.totalValue, kitId: this.kit.id },
          { seller: this.seller?.id || undefined }
        );
        const { paymentId } = response.data;
        this.$router.push({
          hash: "#pay",
          name: "app.payment.details",
          params: { id: paymentId },
        });
      } catch (err) {
        this.error = err.message || "Ocorreu um erro ao processar o pedido";
      } finally {
        this.loading = false;
      }
    },

    formatItemList(items, kitPrice) {
      if(!items || !items.length) return [];
      const itemsTotalValue = items.reduce(
        (total, item) => total + item.TicketBlock.price,
        0
      );
      const formattedItems = items.map((item) => {
        const proportion = item.TicketBlock.price / itemsTotalValue;
        const proportionalPrice = proportion * kitPrice;
        return {
          ...item,
          proportionalPrice: proportionalPrice,
        };
      });
      return formattedItems;
    },

    open({ kit }) {
      this.kit = kit;
      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
    },
  },

  computed: {
    totalValue() {
      return this.kit.price + this.kit.fee;
    },
    formattedItemList() {
      return this.formatItemList(this.kit.Items, this.kit.price);
    },
  },
  props: {
    party: {
      type: Object | null,
      required: true,
    },
    seller: {
      type: Object | null,
      default: null,
    },
  },
  mounted() {
    this.$root.$on("buy-kit", this.open);
  },
};
</script>

<style></style>
