<template>
  <div :class="{ 'v-dialog--scrollable': scrollable }">
    <v-card elevation="0">
      <v-card-title class="d-flex align-center justify-space-between px-0 pt-0">
        <h6 class="font-weight-bold lh-1 text-18 mb-0">
          {{ kit.name }}
        </h6>
        <h6 class="font-weight-bold lh-1 text-16 mb-0">
          {{ itemsQuantity }} ite{{ itemsQuantity != 1 ? "ns" : "m" }}
        </h6>
      </v-card-title>

      <v-card-text class="px-0">
        <div class="d-flex flex-column gap-3">
          <v-card
            v-for="(item, i) in items"
            :key="i"
            outlined
            class="pa-3 rounded-lg mx-1"
          >
            <div class="d-flex gap-3 align-center">
              <h6 class="mb-0  text-20 font-weight-black">
                {{ item.quantity }}x
              </h6>
              <div>
                <p class="mb-0 lh-1 font-weight-bold">
                  {{ item.TicketBlock.TicketGroup.name }}
                </p>
                <p class="mb-0 lh-1 font-weight-medium">
                  {{ item.TicketBlock.name }}
                </p>
              </div>
            </div>

            <div class="d-flex justify-space-between align-center"></div>
          </v-card>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex flex-column align-stretch pa-0 pt-2 mt-2">
        <div
          class="d-flex align-center justify-space-between text-18 font-weight-black"
        >
          <span class="mb-0 lh-1">Total</span>
          <span class="mb-0 text-22 lh-1">
            {{ totalValue | currency(true) }}
          </span>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    kit: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalValue: {
      type: Number,
      default: () => 0,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemsQuantity() {
      return this.items.reduce((total, item) => item.quantity + total, 0);
    },
  },
};
</script>

<style></style>
