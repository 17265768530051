<template>
  <div>
    <div>
      <v-img
        :src="organization.cover"
        :aspect-ratio="21 / 9"
        class="rounded-xl"
        max-height="260px"
      />
    </div>
    <v-card
      class="pa-2 ml-md-8 ml-lg-12 ml-4"
      elevation="0"
      style="
        border-top-left-radius: 75px !important;
        border-bottom-left-radius: 75px !important;
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 24px !important;
        margin-top: -50px !important;
      "
    >
      <div class="d-flex align-center justify-space-between gap-4">
        <div class="d-flex align-center flex-grow-1 gap-4">
          <base-avatar
            :src="organization.photo"
            :seed="organization.id"
            :size="75"
            avatarStyle="shapes"
            color="secondary lighten-2"
          />
          <div>
            <h5 class="font-weight-bold mb-0">
              {{ organization.name }}
            </h5>
            <v-skeleton-loader
              v-if="loadingWhatsapp"
              type="text"
              width="125"
              class="mt-1"
            ></v-skeleton-loader>
            <a
              v-else-if="whatsappUrl"
              class="font-weight-light"
              @click="openWhatsapp"
            >
              {{ formattedPhone }}
            </a>
          </div>
        </div>
        <div class="text-end">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :href="`mailTo:${organization.email}`"
                icon
                target="_blank"
              >
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>{{ organization.email }}</span>
          </v-tooltip>
          <v-tooltip
            top
            v-for="(sn, i) in organization.socialNetworks"
            :key="i"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :href="socialNetworksItens[sn.type].urlFormatter(sn.url)"
                icon
                v-bind="attrs"
                v-on="on"
                target="_blank"
              >
                <span
                  v-if="socialNetworksItens[sn.type].image"
                  v-html="socialNetworksItens[sn.type].image"
                  class="mr-1"
                ></span>
                <v-icon v-else>{{ socialNetworksItens[sn.type].icon }}</v-icon>
              </v-btn>
            </template>
            <span>{{ socialNetworksItens[sn.type].prefix }}{{ sn.url }}</span>
          </v-tooltip>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import RECORDER_SERVICE from "@/services/support/recorder";

export default {
  data: () => ({
    socialNetworksItens: {
      ig: {
        id: "ig",
        name: "Instagram",
        icon: "mdi-instagram",
        urlFormatter: (url) => `https://instagram.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      fb: {
        id: "fb",
        name: "Facebook",
        icon: "mdi-facebook",
        urlFormatter: (url) => `https://facebook.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      tw: {
        id: "tw",
        name: "Twitter",
        icon: "mdi-twitter",
        urlFormatter: (url) => `https://twitter.com/${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
      yt: {
        id: "yt",
        name: "Youtube",
        icon: "mdi-youtube",
        urlFormatter: (url) => url,
        prefix: "",
        placeholder: "Digite o link do canal",
      },
      tt: {
        id: "tt",
        name: "TikTok",
        image: `<svg fill="currentColor" width="22px" height="22px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>`,
        urlFormatter: (url) => `https://tiktok.com/@${url}`,
        prefix: "@",
        placeholder: "Digite o nome de usuário",
      },
    },
    instance: null,
    loadingWhatsapp: true,
    whatsappUrl: null,
    formattedPhone: "",
  }),
  methods: {
    async fetchInstance() {
      this.loadingWhatsapp = true;
      try {
        const response = await RECORDER_SERVICE.getInstanceById();
        this.instance = response.data[0];
        this.whatsappUrl = `https://wa.me/${this.instance.phone}`;
        this.formattedPhone = this.formatPhone(this.instance.phone);
      } catch (error) {
        console.error("Erro ao buscar instância:", error);
      } finally {
        this.loadingWhatsapp = false;
      }
    },
    openWhatsapp() {
      if (this.whatsappUrl) {
        window.open(this.whatsappUrl, "_blank");
      }
    },
    formatPhone(phone) {
      const cleanPhone = phone.replace(/\D/g, '');
      
      const ddi = cleanPhone.slice(0, 2);
      const ddd = cleanPhone.slice(2, 4);
      const numero = cleanPhone.slice(4);
      
      const numeroFormatado = numero.replace(/(\d{5})(\d{4})/, '$1-$2');
      
      return `+${ddi} (${ddd}) ${numeroFormatado}`;
    },
  },
  mounted() {
    this.fetchInstance();
  },
  props: {
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
