<template>
  <div style="position: relative">
    <v-img class="rounded-xl" :src="party?.cover" :aspect-ratio="16 / 9">
      <template v-slot:placeholder>
        <v-card elevation="0" class="h-full">
          <v-skeleton-loader
            type="image"
            class="rounded-xl h-100"
            height="100%"
          />
        </v-card>
      </template>
    </v-img>
    <div style="position: absolute; top: 10px; left: 15px">
      <v-chip v-if="party?.private" color="info" label x-small>
        <v-icon x-small left>mdi-account-lock</v-icon>
        Privado
      </v-chip>
    </div>
    <div
      v-if="party?.youtubeId"
      style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%"
      class="d-flex align-center justify-center"
    >
      <v-btn fab x-large @click="playVideo" :loading="ytState.loading">
        <v-icon x-large>mdi-play</v-icon>
      </v-btn>
    </div>
    <div
      :id="`player-${id}`"
      class="rounded-xl"
      style="
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.5s;
        display: none;
      "
    />
  </div>
</template>

<script>
export default {
  data: () => ({
    id: Math.random().toString(36).substr(2, 9),
    ytPlayer: null,
    showYtPlayer: false,
    ytState: {
      loading: true,
      ready: false,
      state: null,
    },
    timeout: null,
    timeoutDisplay: null,
  }),
  methods: {
    playVideo() {
      clearTimeout(this.timeout);
      this.showYtPlayer = true;
    },
    onYouTubeIframeAPIReady() {
      const player = new YT.Player(`player-${this.id}`, {
        videoId: this.party?.youtubeId,
        playerVars: {
          color: "white",
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onStateChange,
        },
      });
      this.ytPlayer = player;
    },
    onPlayerReady(event) {
      this.ytState.ready = true;
      this.ytState.loading = false;
    },
    onStateChange(event) {
      if (event.data == 1) this.playVideo();
      else if (event.data == 0) this.showYtPlayer = false;
      else if (event.data == 2)
        this.timeout = setTimeout(() => (this.showYtPlayer = false), 5000);
    },
    install() {
      if (!this.party?.youtubeId) return;
      this.ytState.loading = true;
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
      if (window.ytTag) {
        this.$nextTick(() => {
          return this.onYouTubeIframeAPIReady();
        });
      }
      var tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      window.ytTag = tag;
      document.head.appendChild(tag);
    },
    uninstall() {
      window.onYouTubeIframeAPIReady = undefined;
      this.ytPlayer && this.ytPlayer.destroy();
      this.ytPlayer = null;
    },
  },
  watch: {
    showYtPlayer(val) {
      this.$emit("showVideo", val);
      if (val) {
        this.timeoutDisplay && clearTimeout(this.timeoutDisplay);
        this.ytPlayer.g.style.display = "block";
        this.ytPlayer.g.style.opacity = 100;
        this.ytPlayer.setVolume(100);
        this.ytPlayer.playVideo();
        setTimeout(() => (this.ytPlayer.g.style.display = "block"), 500);
      } else {
        this.ytPlayer.g.style.opacity = 0;
        this.timeoutDisplay = setTimeout(
          () => (this.ytPlayer.g.style.display = "none"),
          500
        );
      }
    },
    party: {
      handler() {
        this.showYtPlayer = false;
        this.uninstall();
        this.$nextTick(() => {
          this.install();
        });
      },
      deep: true,
    },
  },

  mounted() {
    this.install();
  },
  beforeDestroy() {
    this.uninstall();
  },

  props: {
    party: {
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.v-image__image.v-image__image--cover {
  transition: background-image 0.3s ease-in-out;
}
</style>
