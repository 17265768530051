<template>
  <div class="mb-1">
    <v-data-iterator
      sort-by="name"
      :page.sync="page"
      :items="mappedKits"
      :items-per-page="maxKits"
      :hide-default-footer="true"
      :search="search"
      :custom-filter="filterKits"
      :custom-sort="sortKits"
    >
      <template v-slot:header v-if="mappedKits.length > maxKits">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          class="mt-1"
          hide-details
          outlined
          dense
        />
      </template>
      <template v-slot:no-results>
        <div>
          <v-alert value="true" color="info" dense text class="mt-2 mb-0">
            Nenhum kit encontrado
          </v-alert>
        </div>
      </template>

      <template v-slot:default="props">
        <v-row class="px-1">
          <v-col
            v-for="kit in props.items"
            :key="kit.id"
            cols="12"
            sm="6"
            md="6"
            lg="4"
          >
            <div class="card-container" :class="{ dark: $vuetify.theme.dark }">
              <v-card
                class="pa-2 rounded-lg d-flex flex-column gap-1"
                style="z-index: 3"
              >
                <div class="d-flex align-center justify-space-between">
                  <p class="mb-0 text-16 lh-5 font-weight-medium">
                    {{ kit.name }}
                  </p>
                </div>

                <div
                  v-if="kit.status === 'available'"
                  class="d-flex flex-column lh-3"
                >
                  <span class="text-18 font-weight-bold">
                    {{ kit.price | currency(true) }}</span
                  >
                  <small
                    v-if="kit.fee"
                    class="text--secondary lh-1 font-weight-medium"
                  >
                    Taxa: +{{ kit.fee | currency(true) }}
                  </small>
                </div>
                <div v-else class="d-flex flex-column lh-3">
                  <span class="text-16">
                    {{ kit.status === "sold-out" ? "Esgotado" : "Em breve" }}
                  </span>
                </div>

                <v-btn
                  color="primary"
                  text
                  block
                  x-small
                  @click="
                    $root.$emit('kit-itens-details', { kit, ticketGroup: tg })
                  "
                >
                  {{ kit.count.tickets }} Ingresso{{
                    kit.count.tickets > 1 ? "s" : ""
                  }}
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn
                  v-if="kit.status === 'available'"
                  color="primary"
                  block
                  small
                  @click="$emit('buyKit', { kit })"
                >
                  Comprar
                </v-btn>
                <!-- <v-btn v-else disabled block small>
                  {{ kit.status === "sold-out" ? "Esgotado" : "Em breve" }}
                </v-btn> -->
              </v-card>
              <div class="stack-layer" />
              <div class="stack-layer" />
            </div>
          </v-col>
        </v-row>
      </template>

      <template
        v-if="mappedKits.length > maxKits"
        v-slot:footer="{ pagination }"
      >
        <v-pagination
          v-if="pagination.pageCount"
          v-model="page"
          :length="pagination.pageCount"
          :total-visible="5"
          class="mt-3"
          small
        ></v-pagination>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
export default {
  props: {
    tg: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    maxKits: 6,
    search: "",
    page: 1,
    statusOrder: ["available", "pending", "sold-out"],
  }),

  methods: {
    filterKits(items, search) {
      return items.filter((kit) =>
        kit.name.toLowerCase().includes(search.toLowerCase())
      );
    },
    sortKits(items) {
      // sort by status and then by name
      return items.sort((a, b) => {
        const aStatus = this.statusOrder.indexOf(a.status);
        const bStatus = this.statusOrder.indexOf(b.status);
        
        if (aStatus === bStatus) return a.name.localeCompare(b.name);
        
        return aStatus - bStatus;
      });
    },
  },
  computed: {
    mappedKits() {
      return this.tg.kitList.map((kit) => {
        const count = kit.Items.reduce(
          (acc, item) => {
            acc.total += item.quantity;
            if (item.TicketBlock) acc.tickets += item.quantity;

            return acc;
          },
          { total: 0, tickets: 0 }
        );

        return { ...kit, count };
      });
    },
  },
};
</script>

<style lang="scss">
// Definir variáveis para as cores das sombras
$shadow-light: rgba(0, 0, 0, 0.1);
$shadow-dark: rgba(255, 255, 255, 0.02);

$background-light: #f9f9f9;
$background-dark: #333333;

// Mixin para aplicar sombra
@mixin shadow($color) {
  box-shadow: 0 2px 4px $color;
}

// Mixin para aplicar sombra de empilhamento
@mixin stack-shadow($color) {
  box-shadow: 0 4px 6px $color, 0 6px 8px $color;
}

// Estilo do Card
.card-container {
  position: relative;
  width: 100%;

  &.dark {
    .kit-card {
      background-color: $background-dark;
      @include shadow($shadow-dark);
    }

    .stack-layer {
      background-color: $background-dark;
      border: $background-dark 1px solid;
      @include stack-shadow($shadow-dark);

      &:nth-child(3) {
        background-color: darken($background-dark, 10%);
      }
    }
  }
}

// .kit-card {
//   border: 1px solid #ddd;
//   padding: 10px;
//   border-radius: 5px;
//   background-color: $background-light;
//   position: relative;
//   z-index: 3;
//   @include shadow($shadow-light);
// }

// Light
.stack-layer {
  position: absolute;
  top: 6px;
  left: 5px;
  width: 100%;
  height: 100%;
  background-color: $background-light;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: $background-light 1px solid;
  border-radius: 8px;
  z-index: 2;

  &:nth-child(3) {
    top: 11px;
    left: 10px;
    background-color: darken($background-light, 7%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
}
</style>