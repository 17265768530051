<template>
  <v-dialog v-model="isOpen" max-width="850" content-class="rounded-xl">
    <v-card class="rounded-xl">
      <v-card-text class="pa-2" v-if="product">
        <v-row class="mx-0 w-full">
          <v-col cols="12" sm="6" md="7" order-sm="first" order="last">
            <div class="d-flex flex-column gap-2" style="height: 100%">
              <div class="d-flex flex-column">
                <h4 class="mb-0">{{ product.name }}</h4>
                <p class="mb-0 pl-2">{{ product.description }}</p>
              </div>
              <div class="flex-grow-1 d-flex flex-column gap-2">
                <v-card
                  v-for="variant in product.Variants"
                  :key="variant.id"
                  outlined
                  class="rounded-lg pa-2 d-flex align-center gap-2"
                  @click="focusVariant(variant)"
                >
                  <div class="flex-grow-1">
                    <h6 class="mb-0">{{ variant.name }}</h6>
                    <p
                      v-if="product.price.startsAt"
                      class="mb-0 font-weight-medium lh-1"
                    >
                      {{ variant.price | currency(true) }}
                    </p>
                  </div>
                  <div v-if="product.Variants.length > 1">
                    <v-btn
                      v-if="!productCart[variant.id]?.quantity"
                      color="primary"
                      @click="addToCart({ variant, product })"
                    >
                      Adicionar
                    </v-btn>
                    <v-card
                      v-else
                      outlined
                      class="d-flex align-center gap-2 rounded-pill pa-1 align-self-center"
                    >
                      <v-btn icon @click="removeFromCart({ variant })" small>
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <b class="text-center">
                        {{ productCart[variant.id].quantity }}
                      </b>
                      <v-btn
                        icon
                        @click="addToCart({ variant, product })"
                        small
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-card>
                  </div>
                </v-card>
              </div>
              <div
                class="flex-grow-0 d-flex justify-space-between align-end flex-wrap"
              >
                <div>
                  <span v-if="product.price.startsAt">A partir de</span>
                  <span v-else>Por</span>
                  <h4 class="mb-0">
                    {{ product.price.amount | currency(true) }}
                  </h4>
                </div>

                <div class="d-flex flex-column gap-3">
                  <div
                    v-if="product.Variants.length == 1"
                    class="d-flex flex-column"
                  >
                    <v-btn
                      v-if="!productCart[firstVariant.id]?.quantity"
                      color="primary"
                      @click="addToCart({ variant: firstVariant, product })"
                    >
                      Adicionar
                    </v-btn>
                    <v-card
                      v-else
                      outlined
                      class="d-flex align-center gap-2 rounded-pill pa-1 align-self-end"
                    >
                      <v-btn
                        icon
                        @click="removeFromCart({ variant: firstVariant })"
                        small
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <b class="text-center">
                        {{ productCart[firstVariant.id].quantity }}
                      </b>
                      <v-btn
                        icon
                        @click="addToCart({ variant: firstVariant, product })"
                        small
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-card>
                  </div>
                  <v-slide-y-reverse-transition leave-absolute hide-on-leave>
                    <v-btn
                      key="continue"
                      v-if="hasVariantsInCart"
                      color="success"
                      @click="close"
                    >
                      Continuar
                    </v-btn>
                    <v-btn v-else key="close" text @click="close">
                      Fechar
                    </v-btn>
                  </v-slide-y-reverse-transition>
                </div>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="5"
            order-sm="last"
            order="first"
            align-self="center"
          >
            <div>
              <product-gallery
                :product="product"
                :variants="product.Variants"
                minSize="50px"
                maxSize="350px"
                miniaturesSize="50px"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductGallery from "../../admin/products/ProductGallery.vue";
export default {
  components: { ProductGallery },
  props: {
    productCart: {
      type: Object,
      default: () => ({}),
    },
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    product: null,
  }),

  methods: {
    open(product) {
      this.isOpen = true;
      this.product = product;
    },
    close() {
      this.isOpen = false;
    },
    addToCart({ variant, product }) {
      this.$root.$emit("add-product-to-cart", { variant, product });
      this.$root.$emit("focus-variant", variant);
    },
    focusVariant(variant) {
      this.$root.$emit("focus-variant", variant);
    },
    removeFromCart({ variant }) {
      this.$root.$emit("remove-product-from-cart", { variant });
    },
  },
  computed: {
    hasVariantsInCart() {
      if (!this.product || !this.product.Variants) return false;
      return this.product.Variants.some(
        (variant) => this.productCart[variant.id]?.quantity
      );
    },
    firstVariant() {
      return this.product?.Variants[0];
    },
  },
  mounted() {
    this.$root.$on("product-details-modal", this.open);
  },
};
</script>

<style>
</style>