<template>
  <div>
    <h5 class="mb-0">Mapa</h5>
    <div class="d-flex justify-center unselectable non-draggable" style="position: relative;">
      
        <div  @click="openModal" style="position: relative;">
          <v-btn color="primary"  fab style="position: absolute; bottom: 20px; left: 20px;">
          <v-icon>mdi-arrow-expand</v-icon>
        </v-btn>
            <img
            class="rounded-lg"
              :src="imageSrc"
              style="cursor: pointer; max-height: 600px;"
            ></img>
        </div>
    </div>
  

    <v-dialog
      content-class="dialog-no-shadow"
      v-model="modalVisible"
      overlay-opacity="0.7"
      @click:outside="closeModal"
    >
      <v-img height="90vh" contain :src="imageSrc" @click="closeModal"></v-img>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modalVisible: false,
      imageSrc: "",
    };
  },
  methods: {
    openModal() {
      this.modalVisible = true;
    },
    closeModal() {
      this.modalVisible = false;
    },
  },
  mounted() {
    this.imageSrc = this.data?.party?.imageMap || "";
  },
  props: {
    data: {
      type: Object,
    },
  },
};
</script>

<style scoped></style>
