<template>
  <div>
    <div v-if="slicedPos.length" class="mb-3">
      <div class="d-flex justify-space-between align-center gap-2">
        <h5 class="mb-1">
          Ponto{{ slicedPos.length !== 1 ? "s" : "" }} de Venda
        </h5>
        <v-btn
          x-small
          text
          @click="openMap"
        >
          Ver no mapa
        </v-btn>
      </div>

      <v-card class="d-flex flex-column pa-1" outlined rounded="lg">
        <v-card
          v-for="(pos, i) in slicedPos"
          :key="i"
          @click="posRouter(pos)"
          elevation="0"
          class="px-4"
        >
          <v-divider v-if="i !== 0" class="mb-2"></v-divider>
          <div class="d-flex align-center gap-3 my-2">
            <div>
              <p class="mb-0 text-overline lh-1">{{ pos.description }}</p>
              <p class="lh-1 font-weight-bold mb-1">{{ pos.name }}</p>
              <p class="mb-0 lh-1">
                {{ pos.Address.street }}, {{ pos.Address.number }} -
                {{ pos.Address.city }}/ {{ pos.Address.state }}
              </p>
            </div>
            <v-spacer />
            <v-btn
              x-small
              fab
              color="primary"
              elevation="1"
              @click.stop="posRouter(pos)"
            >
              <v-icon small>mdi-map-marker</v-icon>
            </v-btn>
          </div>
        </v-card>

        <v-btn v-if="sellers.length > slice" @click="slice += 3" text small>
          Ver mais
        </v-btn>
      </v-card>
    </div>
    <div v-if="slicedSellers.length" class="mb-3">
      <h5 class="mb-1">Comprar com vendedores</h5>

      <v-card class="d-flex flex-column pa-1" outlined rounded="lg">
        <v-card
          v-for="(seller, i) in slicedSellers"
          :key="seller.id"
          @click="sellerMessage(seller)"
          elevation="0"
          class="px-4"
        >
          <v-divider v-if="i !== 0" class="mb-2"></v-divider>
          <div class="d-flex align-center gap-3 my-2">
            <base-avatar :size="40" :src="seller.photo" :seed="seller.id" />
            <div>
              <p class="mb-0 text-overline lh-1">{{ seller.description }}</p>
              <p class="lh-1 font-weight-bold mb-1">{{ seller.name }}</p>
            </div>
            <v-spacer />
            <v-btn
              x-small
              fab
              color="success"
              elevation="1"
              @click.stop="sellerMessage(seller)"
            >
              <v-icon small>mdi-whatsapp</v-icon>
            </v-btn>
          </div>
        </v-card>

        <v-btn v-if="sellers.length > slice" @click="slice += 3" text small>
          Ver mais
        </v-btn>
      </v-card>
    </div>

    <pos-map
      v-if="slicedPos.length"
      :sellers="sellers"
      :party="party"
      ref="posMap"
      @posRouter="posRouter"
    ></pos-map>
  </div>
</template>

<script>
import PosMap from "./modal/PosMap.vue";
export default {
  components: { PosMap },
  data: () => ({
    slice: 3,
  }),
  methods: {
    posRouter(pos) {
      const link = `https://www.google.com/maps/search/?api=1&query=${pos.Address.name}+${pos.Address.street}+${pos.Address.number}+${pos.Address.city}+${pos.Address.state}`;
      window.open(link, "_blank");
    },
    sellerMessage(seller) {
      const link = `https://api.whatsapp.com/send?phone=${seller.ddi || 55}${
        seller.phone.replace(/\D/g, "") || 0
      }&text=Olá, ${seller.name}! Gostaria de comprar ingressos para o evento ${
        this.party.name
      }.`;

      window.open(link, "_blank");
    },
    openMap() {
      this.$emit("pos-map");
    },
  },
  computed: {
    sellersGrouped() {
      return this.sellers.reduce(
        (acc, seller) => {
          acc[seller.type].push(seller);
          return acc;
        },
        { pos: [], user: [] }
      );
    },
    slicedPos() {
      return this.sellersGrouped.pos.slice(0, this.slice);
    },
    slicedSellers() {
      return this.sellersGrouped.user.slice(0, this.slice);
    },
  },
  props: {
    party: {
      type: Object,
    },
    sellers: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
