<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
    @click:outside="close"
    content-class="rounded-lg"
  >
    <v-card class="pa-4 pb-2 rounded-lg">
      <div v-if="kit">
        <p class="font-weight-bold mb-0">{{ ticketGroup.name }}</p>
        <h6>{{ kit.name }}</h6>
        <p class="text-16 mb-2 font-weight-medium">
          Comprando <b class="font-weight-bold">{{ kit.name }}</b> você
          receberá:
        </p>
        <div class="d-flex flex-column gap-3">
          <v-card
            v-for="(item, i) in kit.Items"
            :key="i"
            outlined
            class="pa-3 rounded-lg mx-1"
          >
            <div class="d-flex gap-3 align-center">
              <h6 class="mb-0 text-20 font-weight-black">
                {{ item.quantity }}x
              </h6>
              <div>
                <p class="mb-0 lh-1 font-weight-bold">
                  {{ item.TicketBlock.TicketGroup.name }}
                </p>
                <p class="mb-0 lh-1 font-weight-medium">
                  {{ item.TicketBlock.name }}
                </p>
              </div>
            </div>

            <div class="d-flex justify-space-between align-center"></div>
          </v-card>
        </div>
      </div>
      <div class="d-flex justify-center mt-4">
        <v-btn text @click="close">Fechar</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    kit: null,
    ticketGroup: null,
  }),
  methods: {
    open({ kit, ticketGroup }) {
      this.dialog = true;
      this.kit = kit;
      this.ticketGroup = ticketGroup;
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.$root.$on("kit-itens-details", this.open);
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
